<template>
    <div>
        <div class="regtop flexab ">
            <img :src="utils.loadImg('logo2.png')" alt=""  height="26" class="point" @click="$router.replace({ name: 'login' })" />
            <!-- <div class="rig flex flexa padr40 "> -->
            <div class="rig flex flexa padr40 point" @click="tab=2">
                <img :src="utils.loadImg('ma.png')" alt="" width="25" height="25" />
            </div>
        </div>
        <div class="v-s-h ">
            <p class="font16 col279"><span class="point" @click="$router.replace({ name: 'login' })">登录></span><span class="col9">用户隐私协议</span></p>
        </div>
        <div class="bgf v-s-g">
            <div class="content" ref="content">

            </div>
        </div>
        
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        async getPrivacyPolicy() {
			try {
				const result = await this.$api.login.getPrivacyPolicy()
                this.$refs.content.innerHTML = result.content
			} catch (e) {
				this.$message.error(e.message)
			}
		},
    },
    mounted() {
        this.getPrivacyPolicy()
    }
})
</script>
<style scoped>
.botxt{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 37px;
    box-sizing: border-box;
}
.regtop{
    background: #fff;
    height: 50px;
    padding: 0 25px;
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1090px;
    z-index: 10;
    box-sizing: border-box;
}
.v-s-h {
    height: 47px;
    background-color: #f7f7f7;
    padding: 50px 180px 0 255px;
    line-height: 47px;
}
.v-s-g {
    padding: 30px 180px 0 255px
}
</style>